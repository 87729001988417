import React, { useContext } from 'react';
import displayINRCurrency from '../helpers/displayCurrency';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import Loading from '../pages/Loading';


const OrderProduct = ({ loading, data = [] }) => {
    const loadingList = new Array(1).fill(null);

    return (
        <div className='grid grid-cols-[repeat(auto-fit,minmax(100px,172px))] md:justify-center gap-3 scrollbar-none'>
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                data.map((product, index) => (
                    <div key={index} className='w-40 bg-white rounded-sm shadow'>
                        <div className='bg-slate-200 h-48 p-4 max-w-[172px] md:min-w-[1px] flex justify-center items-center'>
                            <img src={product.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt='product' />
                        </div>
                        <div className='p-2 grid gap-1'>
                            <h2 className='font-medium text-base md:text-base text-ellipsis line-clamp-1 text-black'>{product.productName}</h2>
                            <div className='flex flex-col gap-1'>
                                <p className='text-red-600 font-medium'>{displayINRCurrency(product.price)}</p>
                                {product.priceBefore !== 0 && (
                                    <p className='text-slate-500 line-through'>{displayINRCurrency(product.priceBefore)}</p>
                                )}
                                <p className='text-slate-500'>Quantity: {product.quantity}</p>
                                <p className='text-slate-500'>Total Price: {displayINRCurrency(product.quantity * product.price)}</p>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default OrderProduct;
