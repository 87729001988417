import React from 'react';

const BuyCardProduct = ({ data, onAddToCart }) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4'>
            {data.map((product) => (
                <div key={product._id} className="border p-4 rounded-lg bg-white shadow-md flex flex-col items-center">
                    <div className='bg-slate-200 h-48 w-full flex justify-center items-center mb-4'>
                        <img src={product?.productImage[0]} className='object-contain h-full w-full hover:scale-110 transition-transform' alt='product' />
                    </div>                    
                    <h3 className="font-medium text-base md:text-base text-ellipsis line-clamp-1 text-black">{product.productName}</h3>
                    <p className="text-gray-600 text-center mb-2">{product.quantity}</p>
                    <p className="text-red-600 font-bold text-center mb-4">{product.price}</p>
                    <button
                        className="bg-red-600 text-white px-4 py-2 rounded"
                        onClick={() => onAddToCart(product)}
                    >
                        Add to Cart
                    </button>
                </div>
            ))}
        </div>
    );
};

export default BuyCardProduct;
