import React, { useContext, useEffect, useState } from 'react';
import SummaryApi from '../common';
import Context from '../context';
import displayINRCurrency from '../helpers/displayCurrency';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

const Cart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalQty, setTotalQty] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    });
    const [name, setName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const context = useContext(Context);
    const loadingCart = new Array(4).fill(null);

    // Fetch cart data including packs
    const fetchData = async () => {
        setLoading(true);

        try {
            // Retrieve product and pack IDs from localStorage
            const cartData = localStorage.getItem('cartItems');
            const parsedCartData = cartData ? JSON.parse(cartData) : [];

            // Extract product and pack IDs from cart data
            const productIds = parsedCartData.filter(item => !item.packId).map(item => item.productId);
            const packIds = parsedCartData.filter(item => item.packId).map(item => item.packId);

            // Fetch products from the server using the extracted IDs
            const productResponse = await fetch(SummaryApi.productsByIds.url, {
                method: SummaryApi.productsByIds.method,
                credentials: 'include',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ productIds }) // Send product IDs in the request body
            });

            const productData = await productResponse.json();
            // Fetch packs from the server using the extracted IDs
            const packResponse = await fetch(SummaryApi.packsByIds.url, {
                method: SummaryApi.packsByIds.method,
                credentials: 'include',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ productIds }) // Send pack IDs in the request body
            });

            const packData = await packResponse.json();

            if (productData.success && packData.success) {
                // Combine products and packs data
                const combinedData = [...productData.data, ...packData.data];

                // Update state with combined data and quantities
                const updatedData = combinedData.map(item => {
                    const cartItem = parsedCartData.find(cartItem => cartItem.productId === item._id || cartItem.packId === item._id);
                    if (cartItem) {
                        return { ...item, quantity: cartItem.quantity };
                    }
                    return item;
                });

                setData(updatedData);
            } else {
                console.error("Error fetching product and pack data:", productData.message, packData.message);
            }
        } catch (error) {
            console.error("Error fetching product and pack data:", error);
        } finally {
            setLoading(false);
        }
    };



    // Fetch user details
    const fetchUserData = async () => {
        try {
            const response = await fetch(SummaryApi.current_user.url, {
                method: SummaryApi.current_user.method,
                credentials: 'include',
                headers: {
                    "content-type": 'application/json'
                },
            });

            const userData = await response.json();

            if (userData.success) {
                const { name, phoneNumber, address } = userData.data;
                setName(name || '');
                setPhoneNumber(phoneNumber || '');
                setAddress({
                    street: address?.street || '',
                    city: address?.city || '',
                    state: address?.state || '',
                    postalCode: address?.postalCode || '',
                    country: address?.country || ''
                });
            } else {
                console.error('Failed to fetch user data:', userData.message);
                // Handle failure (e.g., show error message to user)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            // Handle unexpected errors
        }
    };

    useEffect(() => {
        fetchData();
        fetchUserData();
    }, []);

    // Calculate total quantity and price
    useEffect(() => {
        const qty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
        const price = data.reduce((prev, curr) => prev + (curr.quantity * curr.price), 0);
        setTotalQty(qty);
        setTotalPrice(price);
    }, [data]);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'phoneNumber') {
            setPhoneNumber(value);
        } else {
            setAddress({ ...address, [name]: value });
        }
    };

    // Handle order submission
    const handleOrderSubmit = async () => {
        try {
            if (data.length === 0) {
                toast.error("Your cart is empty. Please add products to your cart.");
                return; // Prevent further execution
            }

            setLoading(true);

            const orderData = {
                name: name,
                phoneNumber: phoneNumber,
                products: data.map(item => ({ productId: item._id, quantity: item.quantity })),
                totalPrice: totalPrice,
                address: address
            };
            const response = await fetch(SummaryApi.createOrder.url, {
                method: SummaryApi.createOrder.method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData)
            });

            const responseData = await response.json();
            if (responseData.success) {
                toast.success('Order placed successfully!');
                localStorage.removeItem('cartItems');
                setData([]);
                setTotalQty(0);
                setTotalPrice(0);
                // Optionally update user context if needed
                context.fetchUserAddToCart();
            } else {
                toast.error(`Failed to place order: ${responseData.message}`);
                console.error('Failed to place order:', responseData.message);
                // Handle failure (e.g., show error message to user)
            }
        } catch (error) {
            toast.error('Error placing order. Please try again later.');
            console.error('Error placing order:', error);
            // Handle unexpected errors
        } finally {
            setLoading(false);
        }
    };

// Function to increase quantity of a product in cart
const increaseQty = async (id) => {
    const updatedData = data.map(item => {
        if (item._id === id) {
            if (item.quantity < item.stock) {  // Check if current quantity is less than available stock
                return { ...item, quantity: item.quantity + 1 };
            } else {
                toast.error("Cannot increase quantity beyond available stock.");
                return item;
            }
        }
        return item;
    });
    updateLocalStorage(updatedData);
    setData(updatedData);
};

    // Function to decrease quantity of a product in cart
    const decreaseQty = async (id) => {
        const updatedData = data.map(item => {
            if (item._id === id && item.quantity > 1) {
                return { ...item, quantity: item.quantity - 1 };
            }
            return item;
        });
        updateLocalStorage(updatedData);
        setData(updatedData);
    };

    // Function to delete a product from cart
    const deleteCartProduct = async (id) => {
        const updatedData = data.filter(item => item._id !== id);
        updateLocalStorage(updatedData);
        setData(updatedData);
        context.fetchUserAddToCart();
    };

    // Update localStorage with new cart data
    const updateLocalStorage = (cartData) => {
        localStorage.setItem('cartItems', JSON.stringify(cartData.map(item => ({
            productId: item._id,
            quantity: item.quantity
        }))));
    };

    return (
        <div className='container mx-auto'>
            {/* Cart items */}
            <div className='flex flex-col lg:flex-row gap-10 lg:justify-between p-4'>
                <div className='w-full max-w-3xl'>
                    {/* Display cart items */}
                    {!loading ? (
                        data.map((product) => (
                            <div key={product._id} className='w-full bg-white h-32 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]'>
                                {/* Display product details */}
                                <div className='w-32 h-32 bg-slate-200'>
                                    {/* Check if product.productImage has elements */}
                                    {product.productImage && product.productImage[0] && (
                                        <img src={product.productImage[0]} className='w-full h-full object-scale-down mix-blend-multiply' alt='error' />
                                    )}

                                    {/* Check if product.packImage has elements if product.productImage doesn't */}
                                    {!product.productImage && product.packImage && product.packImage[0] && (
                                        <img src={product.packImage[0]} className='w-full h-full object-scale-down mix-blend-multiply' alt='error' />
                                    )}
                                </div>
                                <div className='px-4 py-2 relative'>
                                    {/* Delete product button */}
                                    <div className='absolute right-0 text-red-600 rounded-full p-5 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => deleteCartProduct(product._id)}>
                                        <MdDelete />
                                    </div>
                                    <h2 className='text-lg lg:text-xl text-ellipsis line-clamp-1'>{product.productName || product.name}</h2>
                                    <div className='flex items-center justify-between'>
                                        <p className='text-slate-600 font-medium text-lg'>{displayINRCurrency(product.price)}</p>
                                    </div>
                                    <div className='flex items-center gap-3 mt-1'>
                                        {/* Quantity buttons */}
                                        <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded ' onClick={() => decreaseQty(product._id)}>-</button>
                                        <span>{product.quantity}</span>
                                        <button className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded' onClick={() => increaseQty(product._id)}>+</button>
                                        <p className='text-red-600 font-semibold text-lg'>{displayINRCurrency(product.price * product.quantity)}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        // Loading state
                        loadingCart.map((_, index) => (
                            <div key={index} className='w-full bg-white h-32 my-2 border border-slate-300 rounded animate-pulse grid grid-cols-[128px,1fr]'>
                                <div className='w-32 h-32 bg-gray-200'></div>
                                <div className='px-4 py-2 relative'>
                                    <div className='animate-pulse flex justify-between'>
                                        <div className='w-16 h-4 bg-gray-200 rounded'></div>
                                        <div className='w-12 h-4 bg-gray-200 rounded'></div>
                                    </div>
                                    <div className='flex justify-between mt-1 animate-pulse'>
                                        <div className='w-16 h-4 bg-gray-200 rounded'></div>
                                        <div className='w-12 h-4 bg-gray-200 rounded'></div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                {/* Order summary */}
                <div className='w-full lg:w-96 bg-white p-4 rounded-lg shadow'>
                    {/* Delivery Address */}
                    <div className='mt-8'>
                        <h3 className='text-lg font-semibold mb-4'>Delivery Address</h3>
                        <form className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                       
                            <div className='col-span-2 sm:col-span-1'>
                                <label htmlFor='name' className='block text-sm font-medium text-gray-700'>
                                    Name
                                </label>
                                <input
                                    type='text'
                                    name='name'
                                    id='name'
                                    autoComplete='name'
                                    value={name}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                            <div className='col-span-2 sm:col-span-1'>
                                <label htmlFor='phoneNumber' className='block text-sm font-medium text-gray-700'>
                                    Phone
                                </label>
                                <input
                                    type='number'
                                    name='phoneNumber'
                                    id='phoneNumber'
                                    autoComplete='phone'
                                    value={phoneNumber}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                                <style jsx>{`
        /* Hide the spin buttons in Chrome, Safari, Edge, and Opera */
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        /* Hide the spin buttons in Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    `}</style>
                            </div>
                            <div className='col-span-2 sm:col-span-1'>
                                <label htmlFor='street' className='block text-sm font-medium text-gray-700'>
                                    Street Address
                                </label>
                                <input
                                    type='text'
                                    name='street'
                                    id='street'
                                    autoComplete='street-address'
                                    value={address.street}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                            <div>
                                <label htmlFor='city' className='block text-sm font-medium text-gray-700'>
                                    City
                                </label>
                                <input
                                    type='text'
                                    name='city'
                                    id='city'
                                    value={address.city}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                            <div>
                                <label htmlFor='state' className='block text-sm font-medium text-gray-700'>
                                    State/Province
                                </label>
                                <input
                                    type='text'
                                    name='state'
                                    id='state'
                                    value={address.state}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                            <div>
                                <label htmlFor='postal_code' className='block text-sm font-medium text-gray-700'>
                                    Postal Code
                                </label>
                                <input
                                    type='text'
                                    name='postalCode'
                                    id='postal_code'
                                    value={address.postalCode}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                            <div>
                                <label htmlFor='country' className='block text-sm font-medium text-gray-700'>
                                    Country
                                </label>
                                <input
                                    type='text'
                                    name='country'
                                    id='country'
                                    value={address.country}
                                    onChange={handleInputChange}
                                    required
                                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                />
                            </div>
                        </form>
                    </div>
                    <h3 className='text-lg font-semibold mb-4'>Order Summary</h3>
                    <div className='flex justify-between mb-2'>
                        <p className='text-base text-gray-600'>Total Quantity:</p>
                        <p className='text-base text-gray-800'>{totalQty}</p>
                    </div>
                    <div className='flex justify-between mb-2'>
                        <p className='text-base text-gray-600'>Total Price:</p>
                        <p className='text-base text-gray-800'>{displayINRCurrency(totalPrice)}</p>
                    </div>
                    {/* Checkout button */}
                    <button
                        onClick={handleOrderSubmit}
                        disabled={data.length === 0 || loading}
                        className={`w-full mt-4 bg-red-600 text-white py-2 rounded-md hover:bg-red-700 ${data.length === 0 || loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Placing Order...' : 'Place Order'}
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Cart;
