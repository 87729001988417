import React, { useEffect, useState } from 'react';
import UploadPack from '../components/UploadPack';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import AdminPackCard from '../components/AdminPackCard';

const AllPacks = () => {
  const [openUploadPack, setOpenUploadPack] = useState(false);
  const [allPacks, setAllPacks] = useState([]);

  const fetchAllPacks = async () => {
    try {
      const response = await fetch(SummaryApi.allPacks.url);
      const dataResponse = await response.json();
      setAllPacks(dataResponse?.data || []);
    } catch (error) {
      toast.error('Error fetching packs');
    }
  };

  const handleDeletePack = async (packId) => {
    try {
      const response = await fetch(`${SummaryApi.deletePack.url}/${packId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      const dataResponse = await response.json();

      if (dataResponse.success) {
        toast.success('Pack deleted successfully');
        fetchAllPacks(); // Refresh the list of packs
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      toast.error('Error deleting pack: ' + error.message);
    }
  };

  useEffect(() => {
    fetchAllPacks();
  }, []);

  return (
    <div className='container'>
      <div className='bg-white py-2 px-4 flex justify-between items-center'>
        <h2 className='font-bold text-lg'>All Packs</h2>
        <button
          className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full'
          onClick={() => setOpenUploadPack(true)}
        >
          Upload Pack
        </button>
      </div>

      {/* All packs */}
      <div className='flex flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
        {allPacks.map((pack, index) => (
          <AdminPackCard
            data={pack}
            key={index + "allPacks"}
            onDelete={handleDeletePack} // Pass the delete handler
            fetchdata={fetchAllPacks}
          />
        ))}
      </div>

      {/* Upload pack component */}
      {openUploadPack && (
        <UploadPack onClose={() => setOpenUploadPack(false)} fetchData={fetchAllPacks} />
      )}
    </div>
  );
};

export default AllPacks;
