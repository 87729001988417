import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const Dashboard = () => {
    const [summaryData, setSummaryData] = useState(null);

    useEffect(() => {
        const fetchSummaryData = async () => {
            try {
                const response = await fetch(SummaryApi.dashboard.url, {
                    method: SummaryApi.dashboard.method,
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch dashboard summary data');
                }

                const responseData = await response.json();
                if (responseData.success) {
                    setSummaryData(responseData.data);
                } else {
                    toast.error('Error fetching dashboard data: ' + responseData.message);
                }
            } catch (error) {
                toast.error('Error fetching dashboard data: ' + error.message);
            }
        };

        fetchSummaryData();
    }, []);

    if (!summaryData) {
        return <div>Loading...</div>;
    }


    // Data for charts
    const productChartData = {
        labels: summaryData.products.map(product => product.productName),
        datasets: [
            {
                label: 'Quantity',
                data: summaryData.products.map(product => product.quantity),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const orderChartData = {
        labels: summaryData.orders.map(order => new Date(order.createdAt).toLocaleDateString()),
        datasets: [
            {
                label: 'Revenue',
                data: summaryData.orders.map(order => order.totalPrice),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };

    const overallSummaryData = {
        labels: ['Total Products', 'Total Orders', 'Total Revenue'],
        datasets: [
            {
                label: 'Summary',
                data: [
                    summaryData.productData.totalProducts,
                    summaryData.orderData.totalOrders,
                    summaryData.orderData.totalRevenue,
                ],
                
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                ],
            },
        ],
    };

    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            <div className="chart-container">
                <h2>Product Quantities: { summaryData.productData.totalProducts }</h2>
                <h3>  </h3>
                <Bar data={productChartData} />
            </div>
            <div className="chart-container">
                <h2>Order Revenue Over Time</h2>
                <Line data={orderChartData} />
            </div>
            <div className="chart-container">
                <h2>Overall Summary</h2>
                <Pie data={overallSummaryData} />
            </div>
        </div>
    );
};

export default Dashboard;
