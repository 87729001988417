import React, { useContext, useEffect, useState } from 'react';
import Logo from './Logo';
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';
import { getCart } from '../helpers/localStorageCart'; // Import the helper function

const Header = () => {
  const user = useSelector(state => state?.user?.user);
  const dispatch = useDispatch();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);
  const [cartProductCount, setCartProductCount] = useState(0); // State to hold cart count
  useEffect(() => {
    const updateCartProductCount = () => {
        // Retrieve cart items from localStorage
        const cartData = localStorage.getItem('cartItems');
        
        // Parse the JSON string to an array
        const parsedCartData = cartData ? JSON.parse(cartData) : [];
        
        // Calculate the total quantity of all items in the cart
        const totalQuantity = parsedCartData.reduce((total, item) => total + item.quantity, 0);
        
        // Set the cart product count
        setCartProductCount(totalQuantity);
    };

    // Update cart product count on component mount
    updateCartProductCount();
}, []); // Only run once on component mount

  const handleLogout = async () => {
    localStorage.removeItem('token');
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      navigate(`/search?q=${value}`);
    } else {
      navigate("/");
    }
  };

  return (
    <header className='h-16 shadow-md bg-slate-300 fixed w-full z-40'>
      <div className=' h-full container mx-auto flex items-center px-4 justify-between gap-1'>
        <div className=''>
          <Link to={"/"}>
            <Logo w={50} h={50} />
          </Link>
        </div>

        <div className='relative border rounded-full focus-within:shadow pl-0 '>
          <input
            type='text'
            placeholder='Search...'
            className='w-full outline-none px-4 py-2 rounded-full border-amber-900'
            onChange={handleSearch}
            value={search}
          />
          <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
            <GrSearch className='h-6 w-6 text-black  focus:border-blue-500 focus:ring-1 focus:ring-blue-500' />
          </div>
        </div>
        <div className='flex items-center gap-3'>
          <div className='relative flex justify-center'>
            {
              user?._id && (
                <div className='text-3xl cursor-pointer relative flex justify-center' onClick={() => setMenuDisplay(preve => !preve)}>
                  {
                    user?.profilePic ? (
                      <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                    ) : (
                      <FaRegCircleUser />
                    )
                  }
                </div>
              )
            }

            {
              menuDisplay && (
                <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded' >
                  <nav>
                    {
                      user?.role === ROLE.ADMIN && (
                        <Link to={"/admin-panel/dashboard"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>Admin Panel</Link>
                      )
                    }
                  </nav>
                </div>
              )
            }
          </div>

          {
            (
              <Link to={"/cart"} className='text-2xl relative'>
                <span><FaShoppingCart /></span>

                {/* <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                  <p className='text-sm'>{cartProductCount}</p>
                </div> */}
              </Link>
            )
          }

          <div>
            {
              user?._id ? (
                <button onClick={handleLogout} className='px-3 py-1 rounded-full text-white bg-black hover:bg-slate-600 '>Logout</button>
              )
                : (
                  <Link to={"/login"} className='px-3 py-1 rounded-full text-white bg-black hover:bg-slate-700'>Login</Link>
                )
            }
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
