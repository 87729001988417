import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import BuyCardProduct from '../components/BuyCardProduct';
import { useNavigate } from 'react-router-dom';
import displayINRCurrency from '../helpers/displayCurrency'; // Import your currency display helper

const Buy = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();

    // Fetch all products
    const fetchProducts = async () => {
        try {
            const response = await fetch(SummaryApi.allProduct.url, {
                method: SummaryApi.allProduct.method,
                credentials: 'include',
            });

            const dataResponse = await response.json();

            if (dataResponse.success) {
                setProducts(dataResponse.data);
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error fetching products: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Add product to cart
    const handleAddToCart = (product) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find(item => item._id === product._id);
            if (existingProduct) {
                return prevCart.map(item =>
                    item._id === product._id
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                );
            }
            return [...prevCart, { ...product, quantity: 1 }];
        });
        toast.success('Product added to cart!');
    };

    // Increase product quantity in cart
    const handleIncreaseQuantity = (productId) => {
        setCart((prevCart) =>
            prevCart.map(item =>
                item._id === productId
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
        );
    };

    // Decrease product quantity in cart
    const handleDecreaseQuantity = (productId) => {
        setCart((prevCart) =>
            prevCart.map(item =>
                item._id === productId && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            ).filter(item => item.quantity > 0)
        );
    };

    // Remove product from cart
    const handleRemoveFromCart = (productId) => {
        setCart((prevCart) =>
            prevCart.filter(item => item._id !== productId)
        );
        toast.success('Product removed from cart!');
    };

    // Handle buy now for all products in the cart
    const handleBuyNow = async () => {
        try {
            const productDetails = cart.map(product => ({
                productId: product._id,
                quantity: product.quantity
            }));

            const response = await fetch(SummaryApi.buyNow.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ products: productDetails }),
                credentials: 'include',
            });

            const dataResponse = await response.json();

            if (dataResponse.success) {
                toast.success('Products purchased successfully! Redirecting to checkout...');
             
                setCart([]); // Clear the cart
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error buying products: ' + error.message);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    // Calculate total quantity and price
    const totalQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    const totalPrice = cart.reduce((acc, item) => acc + (item.quantity * item.price), 0);

    return (
        <div className='flex flex-col w-full p-4'>
            {/* Display the cart */}
            <div className='mt-6'>
                <h3 className='text-xl font-semibold mb-4'>Cart</h3>
                {cart.length > 0 ? (
                    <div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                            {cart.map(product => (
                                <div key={product._id} className="border p-4 rounded-lg bg-white shadow-md flex flex-col items-center">
                                    <div className='bg-slate-200 h-48 w-full flex justify-center items-center mb-4'>
                                        <img src={product?.productImage[0]} className='object-contain h-full w-full hover:scale-110 transition-transform' alt='product' />
                                    </div>
                                    <h4 className='font-medium text-base md:text-base text-ellipsis line-clamp-1 text-black'>{product.productName}</h4>
                                    <p className='text-red-600 font-bold text-center mb-4'>{displayINRCurrency(product.price)}</p>
                                    <div className='flex gap-2'>
                                        <button
                                            className='bg-gray-300 text-black px-2 py-1 rounded'
                                            onClick={() => handleDecreaseQuantity(product._id)}
                                        >
                                            -
                                        </button>
                                        <p className='text-gray-600 text-center mb-2'>{product.quantity}</p>
                                        <button
                                            className='bg-gray-300 text-black px-2 py-1 rounded'
                                            onClick={() => handleIncreaseQuantity(product._id)}
                                        >
                                            +
                                        </button>
                                        <button
                                            className='bg-gray-300 text-black px-2 py-1 rounded'
                                            onClick={() => handleRemoveFromCart(product._id)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='mt-4'>
                            <div className='flex justify-between mb-2'>
                                <p className='text-base text-gray-600'>Total Quantity:</p>
                                <p className='text-base text-gray-800'>{totalQuantity}</p>
                            </div>
                            <div className='flex justify-between mb-2'>
                                <p className='text-base text-gray-600'>Total Price:</p>
                                <p className='text-base text-gray-800'>{displayINRCurrency(totalPrice)}</p>
                            </div>
                            <button
                                className='bg-red-600 text-white px-4 py-2 rounded mt-4'
                                onClick={handleBuyNow}
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>No products in cart.</p>
                )}
            </div>

            <h2 className='text-2xl font-semibold mb-4 mt-6'>Buy Products</h2>
            <div className="flex-1 justify-center">
                {/* Display loading message if loading */}
                {loading && <h1>Loading...</h1>}

                {/* Display products if available */}
                {!loading && products.length > 0 && (
                    <BuyCardProduct
                        data={products}
                        onAddToCart={handleAddToCart}
                    />
                )}
            </div>
        </div>
    );
};

export default Buy;
