// router.js
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Login from '../pages/Login';
import ForgotPassowrd from '../pages/ForgotPassowrd';
import SignUp from '../pages/SignUp';
import AdminPanel from '../pages/AdminPanel';
import AllUsers from '../pages/AllUsers';
import AllProducts from '../pages/AllProducts';
import CategoryProduct from '../pages/CategoryProduct';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import SearchProduct from '../pages/SearchProduct';
import AllOrders from '../pages/AllOrdes';
import AllPacks from '../pages/AllPacks';
import PackDetails from '../pages/PackDetails';
import Buy from '../pages/Buy';
import Dashboard from '../pages/Dashboard';
import PurchaseList from '../pages/PurchaseList';

const router = createBrowserRouter([
    {
            path: "/",
            element: <App />,
        children: [
            { path: "", element: <CategoryProduct /> },
            { path: "login", element: <Login /> },
            { path: "forgot-password", element: <ForgotPassowrd /> },
            { path: "sign-up", element: <SignUp /> },
            { path: "product/:id", element: <ProductDetails /> },
            { path: "pack/:id", element: <PackDetails /> },
            { path: "cart", element: <Cart /> },
            { path: "search", element: <SearchProduct /> },
            {
                path: "admin-panel",
                element: <AdminPanel />,
                children: [
                    { path: "dashboard", element: <Dashboard /> },
                    { path: "all-users", element: <AllUsers /> },
                    { path: "all-products", element: <AllProducts /> },
                    { path: "all-packs", element: <AllPacks /> },
                    { path: "all-orders", element: <AllOrders /> },
                    { path: "buy", element: <Buy /> },
                    { path: "purchases", element: <PurchaseList /> }
                ]
            },
        ]
    }
]);

export default router;
