import React, { useState } from 'react';
import { CgClose } from "react-icons/cg";
import productCategory from '../helpers/productCategory'; // Assuming product categories are similar to packs
import { FaCloudUploadAlt } from "react-icons/fa";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import { MdDelete } from "react-icons/md";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const AdminEditPack = ({
  onClose,
  packData,
  fetchData
}) => {
  const [data, setData] = useState({
    ...packData,
    name: packData?.name,
    price: packData?.price,
    description: packData?.description,
    image: packData?.image || [],
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUploadPack = async (e) => {
    const file = e.target.files[0];
    const uploadedImageCloudinary = await uploadImage(file);

    setData((prev) => ({
      ...prev,
      image: [...prev.image, uploadedImageCloudinary.url]
    }));
  };

  const handleDeletePackImage = (index) => {
    const newPackImages = [...data.image];
    newPackImages.splice(index, 1);

    setData((prev) => ({
      ...prev,
      image: [...newPackImages]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${SummaryApi.updatePack.url}/${data._id}`, {
        method: SummaryApi.updatePack.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success(responseData.message);
        onClose();
        fetchData();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error('Error updating pack:', error);
      toast.error('Failed to update pack. Please try again.');
    }
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>

        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Edit Pack</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='name'>Pack Name:</label>
          <input
            type='text'
            id='name'
            placeholder='Enter pack name'
            name='name'
            value={data.name}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='price' className='mt-3'>Price:</label>
          <input
            type='number'
            id='price'
            placeholder='Enter price'
            value={data.price}
            name='price'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='description' className='mt-3'>Description:</label>
          <textarea
            className='h-28 bg-slate-100 border resize-none p-1'
            placeholder='Enter pack description'
            rows={3}
            onChange={handleOnChange}
            name='description'
            value={data.description}
          >
          </textarea>

          <label htmlFor='image' className='mt-3'>Pack Images:</label>
          <label htmlFor='uploadImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Upload Pack Image</p>
                <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadPack} />
              </div>
            </div>
          </label>
          <div>
          {
                 data?.packImage[0] ? (
                     <div className='flex items-center gap-2'>
                         {
                           data.packImage.map((el,index)=>{
                             return(
                               <div className='relative group'>
                                   <img 
                                     src={el} 
                                     alt={el} 
                                     width={80} 
                                     height={80}  
                                     className='bg-slate-100 border cursor-pointer'  
                                     onClick={()=>{
                                       setOpenFullScreenImage(true)
                                       setFullScreenImage(el)
                                     }}/>

                                     <div className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer' onClick={()=>handleDeletePackImage(index)}>
                                       <MdDelete/>  
                                     </div>
                               </div>
                               
                             )
                           })
                         }
                     </div>
                 ) : (
                   <p className='text-red-600 text-xs'>*Please upload product image</p>
                 )
               }
          </div>

          <button className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700'>Update Pack</button>
        </form>

      </div>

      {/*** Display full screen image */}
      {
        openFullScreenImage && (
          <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
        )
      }

    </div>
  );
};

export default AdminEditPack;
