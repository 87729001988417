import React, { useState } from 'react';
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import AdminEditPack from './AdminEditPack';
import displayINRCurrency from '../helpers/displayCurrency';

const AdminPackCard = ({
    data,
    onDelete, // Add onDelete function as a prop
    fetchdata
}) => {
    const [editPack, setEditPack] = useState(false);

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this pack?')) {
            onDelete(data._id); // Call the onDelete function passed from the parent
        }
    };

    return (
        <div className='bg-white p-4 rounded'>
            <div className='w-40'>
                <div className='w-32 h-32 flex justify-center items-center'>
                    <img src={data?.packImage[0]} className='mx-auto object-fill h-full' alt={data.name} />
                </div>
                <h1 className='text-ellipsis line-clamp-2'>{data.name}</h1>
                <div>
                    <p className='font-semibold'>
                        {displayINRCurrency(data.price)}
                    </p>

                    <div className='flex gap-2 mt-2'>
                        <div
                            className='w-fit p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer'
                            onClick={() => setEditPack(true)}
                        >
                            <MdModeEditOutline />
                        </div>
                        <div
                            className='w-fit p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer'
                            onClick={handleDelete}
                        >
                            <MdDeleteOutline />
                        </div>
                    </div>
                </div>
            </div>

            {editPack && (
                <AdminEditPack packData={data} onClose={() => setEditPack(false)} fetchdata={fetchdata} />
            )}
        </div>
    );
};

export default AdminPackCard;
