import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assest/output-onlinepngtools.png';

const Footer = () => {
  return (
    <footer
      class="flex flex-col items-center bg-zinc-50 text-center text-surface dark:bg-neutral-900 dark:text-white lg:text-left">
      <div class="container p-0">
        <div class="grid gap-4 lg:grid-cols-2">


          <div class="mb-6 md:mb-0 mt-12">
            <h5 class="mb-2 font-medium uppercase text-center">Contact Us</h5>

            <p class="mb-4 text-center">
              <a href="mailto:contact@spartan.com" class="text-surface dark:text-white
          hover:text-primary dark:hover:text-primary">contact@spartan.com</a>
            </p>
            <p class="mb-4 text-center">
              <a href="https://www.facebook.com/profile.php?id=100063627596191" class="hover:underline " target="_blank" rel='noreferrer'>Facebook</a>
            </p>
            <p class="mb-4 text-center">
              <a href="https://www.instagram.com/nutrition.spartan/" class="hover:underline " target="_blank" rel='noreferrer'>Instagram</a>
            </p>
          </div>
          <div class="w-full bg-black/5 p-4 text-center">
            <Link to={"/"} class='flex items-center justify-center'><img className='w-40' src={Logo} alt="" /></Link>
            © 2024 Copyright: Spartan Nutrition
          </div>
        </div>
      </div>


    </footer>
  )
}

export default Footer