import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import './HomeI.css';

// Import images for both desktop and mobile
import bg from '../../assest/bgDesk.png';
import ay from '../../assest/ayyDesk.png';
import bgMob from '../../assest/bgMob.png';
import ayMob from '../../assest/ayyMob.png';

const HomeI = () => {
  // State to track screen width
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Function to update the state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add an event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Select images based on the screen size
  const bgImage = isMobile ? bgMob : bg;
  const ayImage = isMobile ? ayMob : ay;

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 5000, // Delay between transitions in milliseconds
        disableOnInteraction: false, // Keep autoplay running after user interactions
      }}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={bgImage} alt="Background" className="bigLogo" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={ayImage} alt="Logo" className="bigLogo" />
      </SwiperSlide>
      {/* Add more slides if needed */}
    </Swiper>
  );
};

export default HomeI;
