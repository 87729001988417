const productCategory = [
    { id: 1, label: "Whey", value: "whey" },
    { id: 2, label: "Mass Gainer", value: "mass-gainer" },
    { id: 3, label: "Acide Amine", value: "acide-amine" },
    { id: 4, label: "Preworkout", value: "preworkout" },
    { id: 5, label: "Vitamins", value: "multi-vitamins" },
    { id: 6, label: "Snacks", value: "snacks" },
    { id: 7, label: "Sample", value: "sample" },
    { id: 8, label: "Accessoires", value: "accessoires" },
    { id: 9, label: "Shakers", value: "shakers" },
    { id: 10, label: "Fat Burner", value: "fat-burner" },
    { id: 11, label: "Creatine", value: "creatine" },
    { id: 12, label: "Carbs", value: "carbs" },
    { id: 13, label: "Testo Booster", value: "testo-booster" },
    { id: 14, label: "Joint Flex", value: "joint-flex" },
    { id: 15, label: "Fish Oil", value: "fish-oil" },
    { id: 15, label: "Post Workout", value: "post-workout" },
];

export default productCategory