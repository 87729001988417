import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROLE from '../common/role';
import { FaBars, FaRegCircleUser } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';

const AdminPanel = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.role !== ROLE.ADMIN) {
            navigate('/');
        }
    }, [navigate, user]);

    const toggleSidebar = () => {
        setIsSidebarOpen(prev => !prev);
    };

    return (
        <div className='container mt-8'>
            {/* Sidebar Navigation */}
            <aside className={`fixed top-14 left-0 w-64 bg-blue-900 dark:bg-gray-900 h-full text-white transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:w-64`}>
                <div className='h-32 flex flex-col items-center justify-center'>
                    <div className='text-5xl cursor-pointer flex justify-center'>
                        {user?.profilePic ? (
                            <img src={user?.profilePic} className='w-20 h-20 rounded-full' alt={user?.name} />
                        ) : (
                            <FaRegCircleUser />
                        )}
                    </div>
                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                    <p className='text-sm'>{user?.role}</p>
                </div>
                <nav className='mt-4'>
                    <Link to={"dashboard"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Dashboard</Link>
                    <Link to={"all-users"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Users</Link>
                    <Link to={"all-products"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Products</Link>
                    <Link to={"all-packs"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Packs</Link>
                    <Link to={"all-orders"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Orders</Link>
                    <Link to={"buy"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Buy</Link>
                    <Link to={"purchases"} className='block px-4 py-2 hover:bg-blue-800 dark:hover:bg-gray-600'>Purchase List</Link>
 
                </nav>
            </aside>

            {/* Mobile Menu Button */}
            <button className='fixed top-16 left-0 p-3 text-white bg-blue-900 dark:bg-gray-900 z-50 md:hidden' onClick={toggleSidebar}>
                {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </button>

            {/* Main Content */}
            <main className={`flex-1 p-4 transition-transform duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <div className='bg-white shadow-md rounded-lg p-4'>
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default AdminPanel;
