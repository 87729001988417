import React, { useState } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import imageTobase64 from '../helpers/imageTobase64';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",
        confirmPassword: "",
        profilePic: "",
        phoneNumber: "",
        address: {
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: ""
        }
    });
    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name.includes("address.")) {
            const addressField = name.split(".")[1];
            setData(prev => ({
                ...prev,
                address: {
                    ...prev.address,
                    [addressField]: value
                }
            }));
        } else {
            setData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleUploadPic = async (e) => {
        const file = e.target.files[0];
        const imagePic = await imageTobase64(file);
        setData(prev => ({
            ...prev,
            profilePic: imagePic
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (data.password === data.confirmPassword) {
            const dataToSend = {
                email: data.email,
                password: data.password,
                name: data.name,
                profilePic: data.profilePic,
                phoneNumber: data.phoneNumber,
                address: {
                    street: data.address.street,
                    city: data.address.city,
                    state: data.address.state,
                    postalCode: data.address.postalCode,
                    country: data.address.country
                }
            };

            const dataResponse = await fetch(SummaryApi.signUP.url, {
                method: SummaryApi.signUP.method,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dataToSend)
            });

            const dataApi = await dataResponse.json();

            if (dataApi.success) {
                toast.success(dataApi.message);
                navigate("/login");
            }

            if (dataApi.error) {
                toast.error(dataApi.message);
            }

        } else {
            toast.error("Please check password and confirm password");
        }
    };

    return (
        <section id='signup'>
            <div className='mx-auto container p-4'>

                <div className='bg-white p-5 w-full max-w-sm mx-auto'>

                    <div className='w-20 h-20 mx-auto relative overflow-hidden rounded-full'>
                        <div>
                            <img src={data.profilePic || loginIcons} alt='login icons' />
                        </div>
                        <form>
                            <label>
                                <div className='text-xs bg-opacity-80 bg-slate-200 pb-4 pt-2 cursor-pointer text-center absolute bottom-0 w-full'>
                                    Upload Photo
                                </div>
                                <input type='file' className='hidden' onChange={handleUploadPic} />
                            </label>
                        </form>
                    </div>

                    <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
                        <div className='grid'>
                            <label>Name :</label>
                            <div className='bg-slate-100 p-2'>
                                <input
                                    type='text'
                                    placeholder='Enter your name'
                                    name='name'
                                    value={data.name}
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent' />
                            </div>
                        </div>
                        <div className='grid'>
                            <label>Email :</label>
                            <div className='bg-slate-100 p-2'>
                                <input
                                    type='email'
                                    placeholder='Enter email'
                                    name='email'
                                    value={data.email}
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent' />
                            </div>
                        </div>
                        <div>
                            <label>Password :</label>
                            <div className='bg-slate-100 p-2 flex'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Enter password'
                                    value={data.password}
                                    name='password'
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent' />
                                <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
                                    <span>
                                        {showPassword ? (<FaEyeSlash />) : (<FaEye />)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Confirm Password :</label>
                            <div className='bg-slate-100 p-2 flex'>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder='Enter confirm password'
                                    value={data.confirmPassword}
                                    name='confirmPassword'
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent' />

                                <div className='cursor-pointer text-xl' onClick={() => setShowConfirmPassword(prev => !prev)}>
                                    <span>
                                        {showConfirmPassword ? (<FaEyeSlash />) : (<FaEye />)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Phone Number :</label>
                            <div className='bg-slate-100 p-2'>
                                <input
                                    type='tel'
                                    placeholder='Enter phone number'
                                    name='phoneNumber'
                                    value={data.phoneNumber}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent' />
                            </div>
                        </div>
                        <div>
                            <label>Address :</label>
                            <div className='bg-slate-100 p-2'>
                                <input
                                    type='text'
                                    placeholder='Street'
                                    name='address.street'
                                    value={data.address.street}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent mb-2' />
                                <input
                                    type='text'
                                    placeholder='City'
                                    name='address.city'
                                    value={data.address.city}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent mb-2' />
                                <input
                                    type='text'
                                    placeholder='State'
                                    name='address.state'
                                    value={data.address.state}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent mb-2' />
                                <input
                                    type='text'
                                    placeholder='Postal Code'
                                    name='address.postalCode'
                                    value={data.address.postalCode}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent mb-2' />
                                <input
                                    type='text'
                                    placeholder='Country'
                                    name='address.country'
                                    value={data.address.country}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent mb-2' />
                            </div>
                        </div>
                        <button className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>Sign Up</button>
                    </form>

                    <p className='my-5'>Already have an account? <Link to={"/login"} className='text-red-600 hover:text-red-700 hover:underline'>Login</Link></p>
                </div>

            </div>
        </section>
    );
};

export default SignUp;
