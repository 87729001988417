import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { FaStar, FaStarHalf } from "react-icons/fa";
import displayINRCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import addToCart from '../helpers/addToCart';
import Context from '../context';

const statusStyles = {
    inStock: {
        bgColor: 'bg-green-200',
        textColor: 'text-green-600',
        text: 'In Stock'
    },
    limitedStock: {
        bgColor: 'bg-yellow-200',
        textColor: 'text-yellow-600',
        text: 'Limited Stock'
    },
    outOfStock: {
        bgColor: 'bg-red-200',
        textColor: 'text-red-600',
        text: 'Out of Stock'
    }
};

const ProductDetails = () => {
    const [data, setData] = useState({
        productName: "",
        brandName: "",
        category: "",
        productImage: [],
        description: "",
        price: "",
        sellingPrice: "",
        stockStatus: ""  // Added stockStatus to state
    });
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const productImageListLoading = new Array(4).fill(null);
    const [activeImage, setActiveImage] = useState("");
    const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
    const [zoomImage, setZoomImage] = useState(false);
    const { fetchUserAddToCart } = useContext(Context);
    const navigate = useNavigate();

    const fetchProductDetails = async () => {
        setLoading(true);
        const response = await fetch(SummaryApi.productDetails.url, {
            method: SummaryApi.productDetails.method,
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ productId: params?.id })
        });
        setLoading(false);
        const dataResponse = await response.json();
        setData(dataResponse?.data);
        setActiveImage(dataResponse?.data?.productImage[0]);
    };

    useEffect(() => {
        fetchProductDetails();
    }, [params]);

    const handleMouseEnterProduct = (imageURL) => {
        setActiveImage(imageURL);
    };

    const handleZoomImage = useCallback((e) => {
        setZoomImage(true);
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = (e.clientX - left) / width;
        const y = (e.clientY - top) / height;
        setZoomImageCoordinate({ x, y });
    }, [zoomImageCoordinate]);

    const handleLeaveImageZoom = () => {
        setZoomImage(false);
    };

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    const handleBuyProduct = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCart();
        navigate("/cart");
    };

    // Determine the stock status style
    const status = data.stockStatus || 'inStock';
    const { bgColor, textColor, text } = statusStyles[status] || statusStyles.inStock;

    return (
        <div className='container lg:mx-36 mt-20 p-4'>
            <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
                {/* Product Image */}
                <div className='h-auto flex flex-row lg:flex-row-reverse gap-4'>
                    <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
                        <img src={activeImage} className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} />
                        {/* Product Zoom */}
                        {zoomImage && (
                            <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
                                <div
                                    className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150'
                                    style={{
                                        background: `url(${activeImage})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}% `
                                    }}
                                ></div>
                            </div>
                        )}
                    </div>
                    <div className='h-full'>
                        {loading ? (
                            <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                                {productImageListLoading.map((el, index) => (
                                    <div className='h-20 w-20 bg-slate-200 rounded animate-pulse' key={"loadingImage" + index}></div>
                                ))}
                            </div>
                        ) : (
                            <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                                {data?.productImage?.map((imgURL, index) => (
                                    <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                                        <img src={imgURL} className='w-full h-full object-scale-down mix-blend-multiply cursor-pointer' onMouseEnter={() => handleMouseEnterProduct(imgURL)} onClick={() => handleMouseEnterProduct(imgURL)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {/* Product Details */}
                {loading ? (
                    <div className='grid gap-1 w-full'>
                        <p className='bg-slate-200 animate-pulse h-6 lg:h-8 w-full rounded-full inline-block'></p>
                        <h2 className='text-2xl lg:text-4xl font-medium h-6 lg:h-8 bg-slate-200 animate-pulse w-full'></h2>
                        <p className='capitalize text-slate-400 bg-slate-200 min-w-[100px] animate-pulse h-6 lg:h-8 w-full'></p>
                        <div className='text-red-600 bg-slate-200 h-6 lg:h-8 animate-pulse flex items-center gap-1 w-full'></div>
                        <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1 h-6 lg:h-8 animate-pulse w-full'>
                            <p className='text-red-600 bg-slate-200 w-full'></p>
                            <p className='text-slate-400 line-through bg-slate-200 w-full'></p>
                        </div>
                        <div className='flex items-center gap-3 my-2 w-full'>
                            <button className='h-6 lg:h-8 bg-slate-200 rounded animate-pulse w-full'></button>
                            <button className='h-6 lg:h-8 bg-slate-200 rounded animate-pulse w-full'></button>
                        </div>
                        <div className='w-full'>
                            <p className='text-slate-600 font-medium my-1 h-6 lg:h-8 bg-slate-200 rounded animate-pulse w-full'></p>
                            <p className='bg-slate-200 rounded animate-pulse h-10 lg:h-12 w-full'></p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='flex flex-col gap-2'>
                            <p className={`px-2 rounded-full inline-block w-fit ${bgColor} ${textColor}`}>{data?.brandName}</p>
                            <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
                            <p className='capitalize text-slate-400'>{data?.category}</p>
                            <p className={`px-2 rounded-full inline-block w-fit ${bgColor} ${textColor}`}>{text}</p>
                            <div className='text-red-600 flex items-center gap-1'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStarHalf />
                            </div>
                            <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
                                <p className='text-red-600'>{displayINRCurrency(data.price)}</p>
                                {data.priceBefore !== 0 && (
                                    <p className='text-slate-400 line-through'>{displayINRCurrency(data.priceBefore)}</p>
                                )}
                            </div>
                            <div className='flex items-center gap-3 my-2'>
                                <button className='border-2 border-black rounded px-3 py-1 min-w-[120px] text-black font-medium hover:bg-black hover:text-white' onClick={(e) => handleBuyProduct(e, data?._id)}>Buy</button>
                                <button className='border-2 border-black rounded px-3 py-1 min-w-[120px] font-medium text-white bg-black hover:text-black hover:bg-white' onClick={(e) => handleAddToCart(e, data?._id)}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <p className='text-slate-600 font-medium mt-10'>Description : </p>
                <p>{data?.description}</p>
            </div>
            {data.category && (
                <CategroyWiseProductDisplay category={data?.category} heading={"Recommended Product"} />
            )}
        </div>
    )
}

export default ProductDetails;
