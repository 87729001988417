const backendDomain = "https://spartan-backend.onrender.com";
// const backendDomain = "http://localhost:8080";

const SummaryApi = {
    // Existing endpoints
    signUP: {
        url: `${backendDomain}/api/signup`,
        method: "post"
    },
    signIn: {
        url: `${backendDomain}/api/signin`,
        method: "post"
    },
    current_user: {
        url: `${backendDomain}/api/user-details`,
        method: "get"
    },
    logout_user: {
        url: `${backendDomain}/api/userLogout`,
        method: 'get'
    },
    allUser: {
        url: `${backendDomain}/api/all-user`,
        method: 'get'
    },
    updateUser: {
        url: `${backendDomain}/api/update-user`,
        method: "post"
    },
    uploadProduct: {
        url: `${backendDomain}/api/upload-product`,
        method: 'post'
    },
    allProduct: {
        url: `${backendDomain}/api/get-product`,
        method: 'get'
    },
    updateProduct: {
        url: `${backendDomain}/api/update-product`,
        method: 'post'
    },
    categoryProduct: {
        url: `${backendDomain}/api/get-categoryProduct`,
        method: 'get'
    },
    categoryWiseProduct: {
        url: `${backendDomain}/api/category-product`,
        method: 'post'
    },
    productDetails: {
        url: `${backendDomain}/api/product-details`,
        method: 'post'
    },
    addToCartProduct: {
        url: `${backendDomain}/api/addtocart`,
        method: 'post'
    },
    addToCartProductCount: {
        url: `${backendDomain}/api/countAddToCartProduct`,
        method: 'get'
    },
    addToCartProductView: {
        url: `${backendDomain}/api/view-card-product`,
        method: 'get'
    },
    productsByIds: {
        url: `${backendDomain}/api/products-by-ids`,
        method: 'post'
    },
    updateCartProduct: {
        url: `${backendDomain}/api/update-cart-product`,
        method: 'post'
    },
    deleteCartProduct: {
        url: `${backendDomain}/api/delete-cart-product`,
        method: 'post'
    },
    searchProduct: {
        url: `${backendDomain}/api/search`,
        method: 'get'
    },
    filterProduct: {
        url: `${backendDomain}/api/filter-product`,
        method: 'post'
    },
    createOrder: {
        url: `${backendDomain}/api/order/create-order`,
        method: 'post'
    },
    deleteProduct: {
        url: `${backendDomain}/api/delete-product`,
        method: 'DELETE'
    },
    // Updated order-related endpoints
    getOrderById: {
        url: `${backendDomain}/api/order/:orderId`,
        method: 'get'
    },
    updateOrder: {
        url: `${backendDomain}/api/order/:orderId`,
        method: 'put'
    },
    updateOrderStatus: {
        url: `${backendDomain}/api/order/status`,
        method: 'put'
    },
    deleteOrder: {
        url: `${backendDomain}/api/order`,
        method: 'delete'
    },
    getAllOrders: {
        url: `${backendDomain}/api/order/get-orders`,
        method: 'get'
    },
    getMyOrders: {
        url: `${backendDomain}/api/order/my-orders`,
        method: 'get'
    },
    // Pack related endpoints
    allPacks: {
        url: `${backendDomain}/api/all-packs`,
        method: 'GET',
    },
    packDetails: {
        url: `${backendDomain}/api/pack-details`,
        method: 'post'
    },
    uploadPack: {
        url: `${backendDomain}/api/create-pack`,
        method: 'POST',
    },
    updatePack: {
        url: `${backendDomain}/api/update-pack`,
        method: 'POST',
    },
    deletePack: {
        url: `${backendDomain}/api/delete-pack`,
        method: 'DELETE',
    },
    packsByIds: {
        url: `${backendDomain}/api/packs-by-ids`,
        method: 'post'
    },
    dashboard: {
        url: `${backendDomain}/api/dashboard-data`,
        method: 'get'
    },
    buyNow: {
        url: `${backendDomain}/api/buy-now`,
        method: 'post'
    },
    getPurchases: {
        url: `${backendDomain}/api/purchases`,
        method: 'GET',
    },
};

export default SummaryApi;
