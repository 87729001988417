import React, { useState, useEffect } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import { MdDelete } from "react-icons/md";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const UploadPack = ({ onClose, fetchData }) => {
  const [allProduct, setAllProduct] = useState([]);
  const [data, setData] = useState({
    name: "",
    selectedProducts: {}, // Object to track selected products
    price: "",
    description: "",
    packImage: [],
    status: "public"
  });
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");

  const fetchAllProduct = async () => {
    try {
      const response = await fetch(SummaryApi.allProduct.url, {
        method: SummaryApi.allProduct.method,
        credentials: 'include',
        headers: {
          "content-type": "application/json"
        }
      });
      const dataResponse = await response.json();

      if (dataResponse.success) {
        setAllProduct(dataResponse.data || []);
      } else {
        toast.error(dataResponse?.message || "Failed to fetch products");
        setAllProduct([]);
      }
    } catch (error) {
      toast.error("An error occurred while fetching products");
      setAllProduct([]);
    }
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUploadPackImage = async (e) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);

    setData((prev) => ({
      ...prev,
      packImage: [...prev.packImage, uploadImageCloudinary.url]
    }));
  };

  const handleDeletePackImage = (index) => {
    const newPackImage = [...data.packImage];
    newPackImage.splice(index, 1);

    setData((prev) => ({
      ...prev,
      packImage: newPackImage
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productIds = Object.keys(data.selectedProducts);

    const response = await fetch(SummaryApi.uploadPack.url, {
      method: SummaryApi.uploadPack.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ ...data, productIds })
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success(responseData?.message);
      onClose();
      fetchData();
    } else if (responseData.error) {
      toast.error(responseData?.message);
    }
  };

  const handleProductSelect = (e) => {
    const selectedProductId = e.target.value;
    const selectedProduct = allProduct.find(product => product._id === selectedProductId);

    if (selectedProduct && !data.selectedProducts[selectedProductId]) {
      setData((prev) => ({
        ...prev,
        selectedProducts: {
          ...prev.selectedProducts,
          [selectedProductId]: selectedProduct
        }
      }));
    }
  };

  const handleRemoveSelectedProduct = (productId) => {
    const updatedSelectedProducts = { ...data.selectedProducts };
    delete updatedSelectedProducts[productId];

    setData((prev) => ({
      ...prev,
      selectedProducts: updatedSelectedProducts
    }));
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>

        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Upload Pack</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='name'>Pack Name :</label>
          <input
            type='text'
            id='name'
            placeholder='enter pack name'
            name='name'
            value={data.name}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='productIds' className='mt-3'>Select Products :</label>
          <select
            placeholder='select products'
            id='productIds'
            name='productIds'
            value={Object.keys(data.selectedProducts)}
            onChange={handleProductSelect}
            className='p-2 bg-slate-100 border rounded'
          >
                        <option value=''>-- Select a product --</option>

            {allProduct.map(product => (
              <option key={product._id} value={product._id}>
                {product.productName}
              </option>
            ))}
          </select>

          {Object.keys(data.selectedProducts).length > 0 && (
            <div className='mt-2'>
              <h3 className='font-semibold'>Selected Products:</h3>
              {Object.values(data.selectedProducts).map(product => (
                <div key={product._id} className='flex justify-between items-center'>
                  <span>{product.productName}</span>
                  <button
                    type='button'
                    className='ml-2 text-red-600 hover:text-red-800'
                    onClick={() => handleRemoveSelectedProduct(product._id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}

          <label htmlFor='price' className='mt-3'>Price :</label>
          <input
            type='number'
            id='price'
            placeholder='enter price'
            value={data.price}
            name='price'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='description' className='mt-3'>Description :</label>
          <textarea
            className='h-28 bg-slate-100 border resize-none p-1'
            placeholder='enter pack description'
            rows={3}
            onChange={handleOnChange}
            name='description'
            value={data.description}
          />

          <label htmlFor='status' className='mt-3'>Status :</label>
          <select value={data.status} name='status' onChange={handleOnChange} className='p-2 bg-slate-100 border rounded'>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>

          <label htmlFor='image' className='mt-3'>Pack Image :</label>
          <label htmlFor='uploadImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Upload Pack Image</p>
                <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadPackImage} />
              </div>
            </div>
          </label>

          {data.packImage.length > 0 && (
            <div className='flex items-center gap-2 mt-2'>
              {data.packImage.map((el, index) => (
                <div key={index} className='relative group'>
                  <img
                    src={el}
                    alt={el}
                    width={80}
                    height={80}
                    className='bg-slate-100 border cursor-pointer'
                    onClick={() => {
                      setOpenFullScreenImage(true);
                      setFullScreenImage(el);
                    }}
                  />
                  <div className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer' onClick={() => handleDeletePackImage(index)}>
                    <MdDelete />
                  </div>
                </div>
              ))}
            </div>
          )}

          <button className='px-3 py-2 bg-red-600 text-white mt-5 hover:bg-red-700'>Upload Pack</button>
        </form>
      </div>

      {openFullScreenImage && (
        <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
      )}
    </div>
  );
};

export default UploadPack;
