import React, { useState } from 'react';
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import { toast } from 'react-toastify';
import SummaryApi from '../common'; // Ensure you have the delete API endpoint here

const AdminProductCard = ({
  data,
  fetchdata
}) => {
  const [editProduct, setEditProduct] = useState(false);

  // Function to handle product deletion with confirmation
  const handleDeleteProduct = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (confirmed) {
      try {
        const response = await fetch(SummaryApi.deleteProduct.url, { // Replace with actual endpoint
          method: SummaryApi.deleteProduct.method, // Ensure the method is correct (e.g., 'DELETE')
          credentials: 'include',
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify({ productId: data._id }) // Send the product ID to be deleted
        });

        const responseData = await response.json();

        if (responseData.success) {
          toast.success(responseData.message);
          fetchdata(); // Refresh the product list
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting the product.");
      }
    }
  };

  return (
    <div className='bg-white p-4 rounded'>
      <div className='w-40'>
        <div className='w-32 h-32 flex justify-center items-center'>
          <img src={data?.productImage[0]} className='mx-auto object-fill h-full' alt='product' />
        </div>
        <h1 className='text-ellipsis line-clamp-2'>{data.productName}</h1>
        <div>
          <p className='font-semibold'>
            {displayINRCurrency(data.price)}
          </p>
        <h2 className='text-ellipsis line-clamp-2'>quantity: {data.quantity}</h2>

          <div className='flex justify-end gap-2 mt-2'>
            <div className='w-fit p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={() => setEditProduct(true)}>
              <MdModeEditOutline />
            </div>
            <div className='w-fit p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer' onClick={handleDeleteProduct}>
              <MdDelete />
            </div>
          </div>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct productData={data} onClose={() => setEditProduct(false)} fetchdata={fetchdata} />
      )}
    </div>
  );
};

export default AdminProductCard;
