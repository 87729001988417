import Logo from "../components/Logo";
import "./Loading.css"
const Loading = () => {
    return (
        <>
            <div class="blur-container">
                <div class="spinner-container">
                    <div class="loader">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Loading;