import { toast } from 'react-toastify';

// Assuming SummaryApi.addToCartProduct.url and SummaryApi.addToCartProduct.method are defined elsewhere

const addToCart = (e, id) => {
    e?.stopPropagation();
    e?.preventDefault();

    // Fetch product details or construct them as needed
    const product = {
        productId: id,
        // Add more details if needed
    };

    // Retrieve existing cart items from localStorage
    let cartItems = localStorage.getItem('cartItems');
    cartItems = cartItems ? JSON.parse(cartItems) : [];

    // Check if the product is already in the cart
    const existingItem = cartItems.find(item => item.productId === product.productId);

        if (existingItem) {
            // Increase quantity or perform any other operation for existing items
            existingItem.quantity += 1;
        } else {
        // Add new product to cart with initial quantity
        cartItems.push({ ...product, quantity: 1 });
    }

    // Update localStorage with the modified cart items
    localStorage.setItem('cartItems', JSON.stringify(cartItems));

    // Notify user with a toast message
    toast.success('Product added to cart');

    // Optionally, return the updated cart items or any other relevant data
    return cartItems;
};

export default addToCart;
