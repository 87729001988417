import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const PurchaseList = () => {
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch purchases from the backend
    const fetchPurchases = async () => {
        try {
            const response = await fetch(SummaryApi.getPurchases.url, {
                method: SummaryApi.getPurchases.method,
                credentials: 'include',
            });

            const dataResponse = await response.json();
            if (dataResponse.success) {
                setPurchases(dataResponse.data);
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error fetching purchases: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPurchases();
    }, []);

    return (
        <div className="flex flex-col w-full p-4">
            <h2 className="text-2xl font-semibold mb-4">Purchase List</h2>

            {loading ? (
                <p>Loading...</p>
            ) : purchases.length > 0 ? (
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr>
                            <th className="border px-4 py-2">Product ID</th>
                            <th className="border px-4 py-2">Quantity</th>
                            <th className="border px-4 py-2">Total</th>
                            <th className="border px-4 py-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchases.map((purchase) => (
                            <tr key={purchase._id}>
                                <td className="border px-4 py-2">
                                    {purchase.products.map((product) => (
                                        <div key={product.productId}>{product.productId}</div>
                                    ))}
                                </td>
                                <td className="border px-4 py-2">
                                    {purchase.products.map((product) => (
                                        <div key={product.productId}>{product.quantity}</div>
                                    ))}
                                </td>
                                <td className="border px-4 py-2">{purchase.totalAmount}</td>
                                <td className="border px-4 py-2">
                                    {new Date(purchase.purchaseDate).toLocaleDateString()}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No purchases found.</p>
            )}
        </div>
    );
};

export default PurchaseList;
