import React, { useContext } from 'react';
import displayINRCurrency from '../helpers/displayCurrency';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import { Link } from 'react-router-dom';
import { FaCartPlus } from 'react-icons/fa';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Navigation } from 'swiper/modules';

const PackCart = ({ loading, data = [] }) => {
    const loadingList = new Array(13).fill(null);
    const { fetchUserAddToCart } = useContext(Context);

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    return (
        <div className=''>
            {
                loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='w-full bg-white rounded-sm shadow'>
                            <div className='p-4 grid gap-3'>
                                <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                                <div className='flex gap-3'>
                                    <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                    <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                </div>
                                <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
                            </div>
                        </div>
                    ))
                ) : (
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        
                        pagination={{ clickable: true }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 200,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Pagination, ]}
                        className="mySwiper"
                    >
                        {data.map((pack, index) => (
                            <SwiperSlide key={index}>
                                
                                <Link to={"/pack/" + pack?._id} className='w-80    bg-white rounded-sm shadow ' >
                                <div className='bg-slate-200 h-48 p-3 w-full flex justify-center items-center'>
                                    <img src={pack?.packImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt='pack' />
                                </div>
                                <div className='p-3 grid justify-center text-center'>
                                    <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{pack?.name}</h2>
                                    <div className='flex gap-3'>
                                    <p className='capitalize text-slate-500'> {pack?.productIds.length} products</p>
                                        <p className='text-slate-500 '>{displayINRCurrency(pack?.price)}</p>
                                        <button className='text-lg w-Toastify__progress-bar--warning bg-transparent hover:bg-slate-700 text-black px-1 py-0.5 rounded-full' onClick={(e) => handleAddToCart(e, pack?._id)}><FaCartPlus /></button>
                                    </div>
                                    <br />
                                </div>
                            </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )
            }
        </div>
    );
};

export default PackCart;
