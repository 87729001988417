import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import moment from 'moment';
import VerticalCard from '../components/VerticalCard';
import OrderProduct from '../components/OrderProduct';

const AllOrders = () => {
    const [allOrders, setAllOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderProducts, setOrderProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    // Filter states
    const [filterStatus, setFilterStatus] = useState('all');
    const [filterDate, setFilterDate] = useState('');

    const fetchAllOrders = async () => {
        try {
            const response = await fetch(SummaryApi.getAllOrders.url, {
                method: SummaryApi.getAllOrders.method,
                credentials: 'include'
            });

            const dataResponse = await response.json();

            if (dataResponse.orders) {
                setAllOrders(dataResponse.orders);
                setFilteredOrders(dataResponse.orders);
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error fetching orders: ' + error.message);
        }
    };

    const fetchProductsByIds = async (productIds, quantities) => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.productsByIds.url, {
                method: SummaryApi.productsByIds.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productIds }),
                credentials: 'include'
            });

            const dataResponse = await response.json();

            if (dataResponse.success) {
                const productsWithQuantities = dataResponse.data.map(product => {
                    const quantity = quantities.find(q => q.productId === product._id)?.quantity || 0;
                    return { ...product, quantity };
                });

                setOrderProducts(productsWithQuantities);
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error fetching products: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (orderId, newStatus) => {
        const userConfirmed = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
        if (!userConfirmed) return;

        try {
            const response = await fetch(`${SummaryApi.updateOrderStatus.url}/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus })
            });

            const dataResponse = await response.json();
            if (dataResponse.success) {
                toast.success('Order status updated successfully');
                setAllOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order._id === orderId ? { ...order, status: newStatus } : order
                    )
                );
                applyFilters(); // Apply filters again after updating status
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error updating order status: ' + error.message);
        }
    };

    const handleDeleteOrder = async (orderId) => {
        const userConfirmed = window.confirm('Are you sure you want to delete this order?');
        if (!userConfirmed) return;
    
        try {
            const response = await fetch(`${SummaryApi.deleteOrder.url}/${orderId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
    
            const dataResponse = await response.json();
            console.log(dataResponse)
            if (dataResponse.success) {
                toast.success('Order deleted successfully');
                // Update orders without fetching again
                setAllOrders((prevOrders) => prevOrders.filter((order) => order._id !== orderId));
                setFilteredOrders((prevOrders) => prevOrders.filter((order) => order._id !== orderId));
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Error deleting order: ' + error.message);
        }
    };
    


    const handleOrderClick = (order) => {
        if (selectedOrder && selectedOrder._id === order._id) {
            setSelectedOrder(null);
            setOrderProducts([]); // Clear products when deselecting an order
        } else {
            setSelectedOrder(order);
            const quantities = order.products.map(product => ({
                productId: product.productId,
                quantity: product.quantity
            }));
            fetchProductsByIds(order.products.map(product => product.productId), quantities);
        }
    };

    const applyFilters = () => {
        let filtered = [...allOrders];
        if (filterStatus !== 'all') {
            filtered = filtered.filter(order => order.status === filterStatus);
        }
        if (filterDate) {
            filtered = filtered.filter(order => moment(order.createdAt).format('YYYY-MM-DD') === filterDate);
        }
        setFilteredOrders(filtered);
    };

    useEffect(() => {
        fetchAllOrders();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [filterStatus, filterDate, allOrders]);

    return (
        <div className='bg-white pb-4'>
            <div className='p-4'>
                <div className='mb-4'>
                    <label className='mr-2'>Filter by Status:</label>
                    <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className='border border-gray-300 rounded-md'
                    >
                        <option value='all'>All</option>
                        <option value='pending'>Pending</option>
                        <option value='confirmed'>Confirmed</option>
                        <option value='shipped'>Shipped</option>
                        <option value='delivered'>Delivered</option>
                    </select>
                </div>
                <div className='mb-4'>
                    <label className='mr-2'>Filter by Date:</label>
                    <input
                        type='date'
                        value={filterDate}
                        onChange={(e) => setFilterDate(e.target.value)}
                        className='border border-gray-300 rounded-md'
                    />
                </div>
            </div>

            <table className='w-full orderTable'>
                <thead>
                    <tr className='bg-black text-white'>
                        <th>Sr.</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Total Price</th>
                        <th>Address</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.map((order, index) => (
                        <React.Fragment key={order._id}>
                            <tr onClick={() => handleOrderClick(order)} className='cursor-pointer hover:bg-gray-100'>
                                <td>{index + 1}</td>
                                <td>{order.name}</td>
                                <td>{order.phoneNumber}</td>
                                <td>
                                    <select
                                        value={order.status}
                                        onChange={(e) => handleStatusChange(order._id, e.target.value)}
                                        disabled={order.status === 'delivered'}
                                        className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-600 focus:border-red-600 sm:text-sm'
                                    >
                                        <option value='pending'>Pending</option>
                                        <option value='confirmed'>Confirmed</option>
                                        <option value='shipped'>Shipped</option>
                                        <option value='delivered'>Delivered</option>
                                    </select>
                                </td>
                                <td>{order.totalPrice}</td>
                                <td>
                                    {order.address
                                        ? `${order.address.street ?? ''}, ${order.address.city ?? ''}, ${order.address.state ?? ''}, ${order.address.postalCode ?? ''}, ${order.address.country ?? ''}`
                                        : 'No address provided'}
                                </td>
                                <td>{moment(order.createdAt).format('LL')}</td>
                                <td>
                                    <button
                                        onClick={() => handleDeleteOrder(order._id)}
                                        className='bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700'
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                            {selectedOrder && selectedOrder._id === order._id && (
                                <tr>
                                    <td colSpan="8">
                                        <div className='p-4'>
                                            <h3 className='text-lg font-semibold'>Products in this Order:</h3>
                                            <OrderProduct loading={loading} data={orderProducts} />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AllOrders;
