import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import productCategory from '../helpers/productCategory';
import VerticalCard from '../components/VerticalCard';
import SummaryApi from '../common';
import PackCart from '../components/PackCart';
import ClipLoader from "react-spinners/ClipLoader";
import Loading from './Loading';
import { FaFilter } from 'react-icons/fa6';
import SponsorCarousel from '../components/SponsorCarousel';
import HomeI from '../components/homeI/HomeI';
import { useSelector } from 'react-redux';

const CategoryProduct = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(true);

  const user = useSelector(state => state.user.user); // Access user information from Redux store

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const [products, setProducts] = useState([]);
  const [packs, setPacks] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const urlCategoryListinArray = urlSearch.getAll("category");

  const urlCategoryListObject = {};
  urlCategoryListinArray.forEach(el => {
    urlCategoryListObject[el] = true;
  });

  const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
  const [filterCategoryList, setFilterCategoryList] = useState(urlCategoryListinArray);

  const [sortBy, setSortBy] = useState("");

  const fetchPacks = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.allPacks.url, {
        method: SummaryApi.allPacks.method,
        headers: {
          "content-type": "application/json"
        }
      });

      const dataResponse = await response.json();
      setPacks(dataResponse?.data || []);
    } catch (error) {
      console.error("Error fetching packs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async (categories) => {
    setLoading(true);
    try {
      const response = await fetch(
        categories.length > 0
          ? SummaryApi.filterProduct.url
          : SummaryApi.allProduct.url,
        {
          method: categories.length > 0
            ? SummaryApi.filterProduct.method
            : SummaryApi.allProduct.method,
          headers: {
            "content-type": "application/json"
          },
          body: categories.length > 0 ? JSON.stringify({ category: categories }) : null
        }
      );

      const dataResponse = await response.json();
      setProducts(dataResponse?.data || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectCategory = (e) => {
    const { value, checked } = e.target;

    setSelectCategory((prev) => {
      return {
        ...prev,
        [value]: checked
      };
    });
  };

  useEffect(() => {
    const arrayOfCategory = Object.keys(selectCategory).filter(categoryKeyName => selectCategory[categoryKeyName]);

    setFilterCategoryList(arrayOfCategory);

    // Format for URL change when change on the checkbox
    const urlFormat = arrayOfCategory.map((el) => `category=${el}`).join("&&");

    navigate("/?" + urlFormat);
  }, [selectCategory, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchPacks();
      await fetchProducts(filterCategoryList);
    };

    fetchData();
  }, [filterCategoryList]);


  const handleOnChangeSortBy = (e) => {
    const { value } = e.target;

    setSortBy(value);

    if (value === 'asc') {
      setProducts(prev => [...prev].sort((a, b) => a.price - b.price));
      setPacks(prev => [...prev].sort((a, b) => a.price - b.price));
    }

    if (value === 'dsc') {
      setProducts(prev => [...prev].sort((a, b) => b.price - a.price));
      setPacks(prev => [...prev].sort((a, b) => b.price - a.price));
    }
  };

  return (
    <div className="container ">
      {/* Greeting and user information */}
      <div className="text-lg py-2 px-4">
            {user ? (
              <p className="text-xl font-semibold">
                Hello, {user.name}! {/* Assuming user has a `name` property */}
              </p>
            ) : (
              <p className="text-xl font-semibold">Hello, Guest!</p>
            )}
            {/* Add more user-specific information here if needed */}
          </div>
    <div className='container'>
      <HomeI/>
      </div>
       <div className='lg:mx-48 '> 
      <div className="container px-1 p-1 ">
        <p className="text-2xl font-semibold py-4 text-center">Pack Collections</p>
        <div className="flex flex-col">
          {packs.length !== 0 && !loading && <PackCart data={packs} loading={loading} />}
        </div>
      </div>
      
      <div className="container pl-2">
        {/***left side */}
        <div className="flex flex-col">
          <div className='flex text-lg text-center mx-2 md:mx-6'>
            <div className='container'>
              <div className='flex pt-2'>
                <button onClick={toggleFilterVisibility} className=" border-1 text-slate-900">
                  {isFilterVisible ? "Hide Filters" : "Show Filters"}
                </button>
                <FaFilter className='justify-between mt-1' />
              </div>
            </div>
            <div className='flex flex-col '>
              <div className="mr-5">
                <form className=" flex flex-col text-lg gap-2 py-2">
                  <div className="flex items-center  gap-3">
                    <select name="sortBy" value={sortBy} onChange={handleOnChangeSortBy} className="text-base border pb-1">
                      <option value="" disabled>Select Sort </option>
                      <option value="asc">Price - Low to High</option>
                      <option value="dsc">Price - High to Low</option>
                    </select>
                  </div>
                </form>
              </div>

            </div>
          </div>
          <div className='flex flex-row'>
            {isFilterVisible && (
              <>
                {/**filter by */}
                <div className="container">
                  {/* <h3 className="text-base font-medium text-slate-500  pb-1 border-slate-300">Category</h3> */}
                  <form className="text-sm grid grid-cols-[repeat(auto-fit,minmax(100px,1px))] justify-center gap-1">
                    {productCategory.map((categoryName, index) => (
                      <div key={index} className="category-container">
                        <input
                          type="checkbox"
                          name={"category"}
                          checked={!!selectCategory[categoryName.value]}
                          value={categoryName.value}
                          id={categoryName.value}
                          onChange={handleSelectCategory}
                          className="hidden-checkbox"
                        />
                        <label htmlFor={categoryName.value} className="category-label">
                          {categoryName.label}
                        </label>
                      </div>
                    ))}
                  </form>

                  <style jsx>{`
        .category-container {
          background-color: rgb(226 232 240);
          // padding: 10px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
                    
        }

        .hidden-checkbox {
          display: none;
        }

        .category-container input:checked + .category-label {
          background-color: black;
          border-radius: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .category-label {
          padding: 10px 0;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
      `}</style>
                </div>
              </>

            )}
          </div >
        </div>
        {/***right side (product) */}
        <div className=" flex-1 justify-center mt-6">
          {/* <p className="font-medium text-slate-800 text-lg my-2">Search Results: {products.length} products</p> */}
          <div className="">
            {loading &&
              <Loading />
            }

            {
              products.length !== 0 && !loading && (
                <VerticalCard data={products} loading={loading} />
              )
            }
          </div>
        </div>
      </div>
      <SponsorCarousel/>
    </div>
    </div>    
  );
};

export default CategoryProduct;
