import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './SponsorCarousel.css'; // Import custom CSS styles
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const SponsorCarousel = () => {
  const sponsors = [
    { src: 'https://p5.storage.canalblog.com/54/13/948867/117578931.png', width: 200 },
    { src: 'https://proteinhouse.mu/cdn/shop/collections/kevin-levrone-703066.webp?v=1710572478', width: 250 },
    { src: 'https://www.impactnutrition.com.tn/wp-content/uploads/2022/09/cropped-Logo-impact.png', width: 155 },
    { src: 'https://williambonacsignature.com/wp-content/uploads/2023/05/cropped-BONAC-LOGO.png', width: 70 },
    { src: 'https://www.v-shapesupps.com/wp-content/uploads/2020/09/LOGO08.png', width: 120 },
    { src: 'https://images.easyfundraising.org.uk/retailer/cropped/logo-gold-standard-nutrition-1678118568.png', width: 120 },
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Ostrovit_logo.png/1200px-Ostrovit_logo.png', width: 160 },
    // { src: 'https://itagroup.hs.llnwd.net/o40/csg/pse-demo/channel-incentive/logos/logo4.jpg', width: 160 },
  ];

  return (
    <div className="sponsor-carousel-container">
      <Swiper
        spaceBetween={10}
        slidesPerView={4}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {sponsors.map((sponsor, index) => (
          <SwiperSlide key={index} className='swiper-slid'>
            <div className="sponsor-feature">
              <img alt="" src={sponsor.src} style={{ width: sponsor.width }} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SponsorCarousel;
