import React, { useContext } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayINRCurrency from '../helpers/displayCurrency'
import Context from '../context'
import addToCart from '../helpers/addToCart'
import { Link } from 'react-router-dom'
import { FaCartPlus } from 'react-icons/fa6'

const VerticalCard = ({ loading, data = [] }) => {
    const loadingList = new Array(4).fill(null)
    const { fetchUserAddToCart } = useContext(Context)

    const handleAddToCart = async (e, id) => {
        addToCart(e, id)
        fetchUserAddToCart()
    }

    return (
        <div className='grid grid-cols-[repeat(auto-fit,minmax(100px,172px))]  justify-center gap-4  scrollbar-none '>
            {

                loading ? (
                    loadingList.map((product, index) => {
                        return (
                            <div className='w-48    bg-white rounded-sm shadow '>
                             <div className='bg-slate-200 h-48 p-4 min-w-[192px] md:min-w-[145px] flex justify-center items-center'>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'></h2>
                                    <p className='capitalize text-slate-500'></p>
                                    <div className='flex gap-3'>
                                        <p className='text-red-600 font-medium'></p>
                                        <p className='text-slate-500 line-through'></p>
                                        <button className='text-lg w-Toastify__progress-bar--warning bg-transparent hover:bg-slate-700 text-black px-1 py-0.5 rounded-full' ></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    data.map((product, index) => {
                        return (
                            <Link to={"/product/" + product?._id} className='w-40  bg-white rounded-sm shadow ' >
                                <div className='bg-slate-200 h-48 p-4 max-w-[172px] md:min-w-[1px] flex justify-center items-center '>
                                    <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt='product' />
                                </div>
                                <div className='p-2 grid gap-1'>
                                    <h2 className='font-medium text-base md:text-base text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                                    {/* <p className='capitalize text-slate-500'>{product?.category}</p> */}
                                    <div className='flex gap-1'>
                                        <div>
                                        <p className='text-red-600 font-medium'>{displayINRCurrency(product?.price)}</p>
                                        {product.priceBefore !== 0 &&(
                                            <p className='text-slate-500 line-through'>{displayINRCurrency(product?.priceBefore)}</p>
                                        )}
                                        </div>
                                        <button className='text-xl w-Toastify__progress-bar--warning bg-transparent hover:text-green-500  text-black px-5 py-0.5 rounded-full' onClick={(e) => handleAddToCart(e, product?._id)}><FaCartPlus className='hover:animate-pulse' /></button>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                )

            }
        </div>
    )
}

export default VerticalCard